import React, { useCallback, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../../components/Loader/Loader";
import "./LastTxs.scss";
import classNames from "classnames";
import { getTxs } from "../../../api/api";
import { formatDistanceToNow } from "date-fns";

type ITxs = {
  txHash: string;
  sender: string;
  txType: number;
  amountIn: number;
  amountOut: number;
  timestamp: string;
};

type Props = {
  tokenAddress: string;
};

// tx.txType === 0 -> BUY
const LastTxs = ({ tokenAddress }: Props) => {
  const [Txs, setTxs] = useState<ITxs[]>([]);

  const txsRuq = useQuery({
    queryKey: ["last-txs", `${tokenAddress}_token`],
    queryFn: () =>
      getTxs(tokenAddress).then((res) => {
        if (res?.data?.data) setTxs(res.data.data);
        return res;
      }),
    enabled: !!tokenAddress,
    refetchInterval: 3000,
  });

  const DateTimeTX = useCallback((tx: ITxs) => {
    return formatDistanceToNow(new Date(tx.timestamp), { addSuffix: true });
  }, []);

  if (txsRuq.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (!Txs.length) return null;

  return (
    <div className="last-txs">
      <table className="txs-table">
        <thead>
          <tr>
            <th className="">Address</th>
            <th className="">ETH</th>
            <th className="">Type</th>
            <th className="">Date / Time</th>
            <th className="">TX Hash</th>
          </tr>
        </thead>
        <tbody>
          {Txs.map((tx, i) => {
            return (
              <tr key={i}>
                <td className="">
                  <a href={`https://basescan.org/address/${tx.sender}`} target="_blank" rel="noreferrer">
                    {tx.sender.slice(0, 4)}...{tx.sender.slice(-4)}
                  </a>
                </td>
                <td className="last-tx-amount">
                  {tx.txType === 0
                    ? parseFloat(new Intl.NumberFormat("en-US", { minimumFractionDigits: 10 }).format(tx.amountIn || 0))
                    : parseFloat(new Intl.NumberFormat("en-US", { minimumFractionDigits: 10 }).format(tx.amountOut || 0))}
                </td>
                <td className={classNames({ "tx-buy": tx.txType === 0, "tx-sell": tx.txType === 1 })}>
                  {tx.txType === 0 ? "buy" : "sell"}
                </td>
                {/* <td className="last-tx-date">{new Date(tx.timestamp).toLocaleString("gr-GR")}</td> */}
                <td className="last-tx-date">{DateTimeTX(tx)}</td>
                <td className="">
                  <a href={`https://basescan.org/tx/${tx.txHash}`} target="_blank" rel="noreferrer">
                    {tx.txHash.slice(0, 6)}...{tx.txHash.slice(-6)}
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default LastTxs;
