import React from "react";
import "./Navbar.scss";
import { NavLink } from "react-router-dom";
import { ConnectButton } from "@rainbow-me/rainbowkit";

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-full-window">
        <NavLink className="logo permanent-marker-regular" to="/">
          Castify
        </NavLink>

        <ConnectButton />
      </div>
    </nav>
  );
};

export default Navbar;
