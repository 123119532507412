import React from "react";
import "./Loader.scss";

interface ILoader {
  size?: number;
}

const Loader = ({ size = 48 }: ILoader) => {
  return <span className="loader" style={{ width: `${size}px`, height: `${size}px` }}></span>;
};

export default Loader;
