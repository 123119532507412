import React, { useState } from "react";
import "./AllTokens.scss";
import Card from "../Card/Card";
import { useQuery } from "@tanstack/react-query";
import { getAllTokens } from "../../../api/api";
import AllTokensPagination from "./AllTokensPagination";
import { useSearchParams } from "react-router-dom";
import Select, { OptionType } from "../../../components/Select/Select";

const Options = [
  {
    id: 1,
    label: "Order By Launched Time",
  },

  {
    id: 2,
    label: "Order By Market Cap",
  },
];

const AllTokens = () => {
  const [getSearch, setSearch] = useSearchParams();
  const showGraduated = getSearch.get("showGraduated");
  const [pagination, setPagination] = useState({ page: 1, size: 40, total: 1 });
  const [searchQuery, setSearchQuery] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [hideGraduated, setHideGraduated] = useState(!showGraduated);
  const [selectedOption, setSelectedOption] = useState<OptionType>(Options[0]);
  const [orderByMCap, setOrderByMCap] = useState(false);

  const tokens = useQuery({
    queryKey: ["all-tokens", pagination.page, searchQuery, hideGraduated, orderByMCap],
    queryFn: () =>
      getAllTokens({
        pageSize: pagination.size,
        pageNumber: pagination.page,
        search: searchQuery,
        hideGraduated,
        orderByMCap: orderByMCap,
      }).then((res) => {
        if (res.total) setPagination((prev) => ({ ...prev, total: res.total }));
        return res;
      }),
  });

  const onOptionClick = (option: OptionType) => {
    setSelectedOption(option);

    if (option.id === 1) setOrderByMCap(false);
    if (option.id === 2) setOrderByMCap(true);
  };

  const onSearch = () => {
    setPagination((prev) => ({ ...prev, page: 1 }));
    if (!searchValue) setSearchQuery("");
    else setSearchQuery(searchValue.trim());
  };

  const onClickHideGraduated = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) getSearch.delete("showGraduated");
    else getSearch.set("showGraduated", "true");

    setSearch(getSearch);
    setHideGraduated(e.target.checked);
  };

  return (
    <div className="all-tokens">
      <h1 id="all-tokens">All Tokens</h1>
      <div className="filters">
        <input
          onKeyDown={(e) => {
            if (e.key === "Enter") onSearch();
          }}
          onChange={(e) => {
            setSearchValue(e.target.value);
            if (e.target.value === "") {
              setPagination((prev) => ({ ...prev, page: 1 }));
              setSearchQuery("");
            }
          }}
          value={searchValue}
          placeholder="Search by token name or address"
          className="search-token"
        />
        <Select Options={Options} setSelectedOption={setSelectedOption} selectedOption={selectedOption} onOptionClick={onOptionClick} />

        <div className="filter-checkbox">
          <input checked={hideGraduated} id="hideGraduated" type="checkbox" onChange={onClickHideGraduated} />
          <label htmlFor="hideGraduated">Hide Listed</label>
        </div>
      </div>
      <div className="tokens-list">
        {tokens?.data?.tokens.map((token, i) => (
          <Card token={token} key={token.address} />
        ))}
      </div>
      <AllTokensPagination isLoading={tokens.isLoading} pagination={pagination} setPagination={setPagination} />
    </div>
  );
};

export default AllTokens;
