import React, { useState } from "react";
import "./TokenHolders.scss";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../../components/Loader/Loader";
import { Tooltip } from "react-tooltip";
import { getHolders } from "../../../api/api";
import classNames from "classnames";

export interface IHolder {
  amount: number;
  holderAddress: string;
  isCreator: boolean;
  share: number;
}

type Props = {
  tokenAddress: string;
};

const TokenHolders = ({ tokenAddress }: Props) => {
  const [HoldresData, setHoldresData] = useState<IHolder[]>([]);

  const holdres = useQuery({
    queryKey: ["token-holdres", `${tokenAddress}_token`],
    queryFn: () =>
      getHolders(tokenAddress).then((res) => {
        if (res?.data?.data) setHoldresData(res.data.data);
        return res;
      }),
    enabled: !!tokenAddress,
    refetchInterval: 5000,
  });

  if (holdres.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (!HoldresData.length) return null;

  return (
    <div className="token-holdres">
      <table className="holdres-table">
        <thead>
          <tr>
            <th className="head-address">Holders</th>
            <th className="head-share">Share</th>
          </tr>
        </thead>
        <tbody>
          {HoldresData.map((holder, i) => {
            return (
              <tr key={i}>
                <td className="address">
                  <a
                    id={holder.isCreator ? "founder" : undefined}
                    href={`https://basescan.org/address/${holder.holderAddress}`}
                    target="_blank"
                    rel="noreferrer"
                    className={classNames(holder.isCreator && "founder")}
                  >
                    {/* {holder.isCreator ? "⭐ " : "  "} */}
                    {holder.holderAddress.slice(0, 5)}...{holder.holderAddress.slice(-8)}
                  </a>
                  {holder.isCreator && <Tooltip anchorSelect="#founder" content="Creator" />}
                </td>
                <td className={classNames("share", holder.isCreator && "founder")}>{holder.share.toFixed(4)} %</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TokenHolders;
