import React, { useEffect, useState } from "react";
import "./Select.scss";
import classNames from "classnames";

export type OptionType = {
  id: number;
  label: string;
};

type Props = {
  selectedOption: OptionType;
  setSelectedOption: React.Dispatch<OptionType>;
  Options: OptionType[];
  onOptionClick: (o: OptionType) => void;
};

const Select = ({ selectedOption, setSelectedOption, Options, onOptionClick }: Props) => {
  const [showSelect, setShowSelect] = useState(false);

  useEffect(() => {
    const clickOnBody = () => {
      setShowSelect(false);
    };
    document.body.addEventListener("click", clickOnBody);

    return () => {
      document.body.removeEventListener("click", clickOnBody);
    };
  }, []);

  return (
    <div className="select">
      <div
        onClick={(e) => {
          e.stopPropagation();
          setShowSelect((prev) => !prev);
        }}
        className={classNames("selected-option", { "selected-option--open": showSelect })}
      >
        {selectedOption.label}
        <span className="select-arrow">▼</span>
      </div>
      <div className={classNames("select-option", { "select-option--show": showSelect })}>
        {Options.map((el) => {
          return (
            <div
              onClick={(e) => {
                e.stopPropagation();
                onOptionClick(el);
                setShowSelect(false);
              }}
              className={classNames("option-value", { "option-value--selected": el.id === selectedOption.id })}
              key={el.id}
            >
              {el.label}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Select;
