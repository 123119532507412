import React from "react";
import { useAccount, useBalance } from "wagmi";
import { formatBalance } from "../../../utils/Utils";

type Props = {
  setMarketValue: React.Dispatch<React.SetStateAction<string>>;
};

const BalanceETH = ({ setMarketValue }: Props) => {
  const account = useAccount();
  const balanceWallet = useBalance({ address: account.address });

  return (
    <p onClick={() => setMarketValue(formatBalance(balanceWallet.data?.formatted || "0")?.toString() || "")} className="user-balance">
      Balance: {formatBalance(balanceWallet.data?.formatted || "0")} ETH
    </p>
  );
};

export default BalanceETH;
