import React, { useContext, useEffect, useMemo } from "react";
import "./Token.scss";
import Chart from "./Chart/Chart";
import Controlls from "./Controlls/Controlls";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getTokenByHash } from "../../api/api";
import LoaderWrapper from "../../components/Loader/LoaderWrapper";
import CheckTokenExist from "./CheckTokenExist/CheckTokenExist";
import { IToken } from "../../api/types";
import { Context } from "../../App";
import { MAX_ETH_VALUE } from "../../_constants";
import TokenValueInfo from "./TokenValueInfo/TokenValueInfo";
import LastTxs from "./LastTxs/LastTxs";
import TokenHolders from "./TokenHolders/TokenHolders";
import WarpcastSVG from "../../components/Icon/Warpcast.svg";
const Token = () => {
  const { hash } = useParams();
  const { ethPrice } = useContext(Context);

  const token = useQuery({
    queryKey: ["token", hash],
    queryFn: () => getTokenByHash(hash || ""),
    enabled: !!hash,
    refetchInterval: 10000,
  });

  useEffect(() => {
    if (token.data && token.data.symbol) document.title = `Castify [ ${token.data.symbol} ]`;

    return () => {
      document.title = "Castify";
    };
  }, [token.data]);

  const Volume = useMemo(() => {
    const vol = new Intl.NumberFormat("en-US", { minimumFractionDigits: 5 }).format(ethPrice * +(token.data?.volume24H || 0));

    if (+vol < 1) return "<0 $";
    else return (+vol).toFixed(0) + " $";
  }, [ethPrice, token.data]);

  return (
    <LoaderWrapper loading={token.isLoading}>
      <CheckTokenExist token={token.data}>
        <div className="token-header">
          <p className="token-header-name">
            <span>[ {token.data?.symbol} ]</span> {token.data?.name}{" "}
          </p>
        </div>
        <div className="created-by">
          <a  rel="noreferrer" target="_blank" href={`https://warpcast.com/${token.data?.extendedData.creator}`}>
            <span>Created by <span className="create-name">{token.data?.extendedData.creator}</span> ( check out on warpcast</span>
            <img src={WarpcastSVG} alt="WARPCAST" />
            <span>)</span>
          </a>
        </div>
        <p className="listed-uniswap">
          When the token reaches {ethPrice > 0 ? `${Math.floor(ethPrice * MAX_ETH_VALUE)} $` : "20 * ETH price"} marketcap it will be listed
          on{" "}
          <a href="https://app.uniswap.org/" target="_blank" rel="noreferrer">
            uniswap
          </a>
        </p>

        <div className="token-value-container">
          <TokenValueInfo label="Market Cap." value={Math.floor(ethPrice * +(token.data?.marketCap || 0)) + " $"} />
          <TokenValueInfo
            label="Price"
            value={new Intl.NumberFormat("en-US", { minimumFractionDigits: 11 }).format(token.data?.price || 0) + " ETH"}
          />
          <TokenValueInfo label="Volume 24H" value={Volume} />
        </div>
        <div className="token">
          <Chart token={token.data as IToken} />
          <Controlls token={token.data as IToken} />
        </div>
        <div className="token-info">
          <LastTxs tokenAddress={token.data?.address || ""} />
          <TokenHolders tokenAddress={token.data?.address || ""} />
        </div>
      </CheckTokenExist>
    </LoaderWrapper>
  );
};

export default Token;
