import React, { useContext, useState } from "react";
import "./Card.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { IToken } from "../../../api/types";
import Progress from "../../../components/Progress/Progress";
import { Context } from "../../../App";
import { MAX_ETH_VALUE } from "../../../_constants";

type Props = {
  token: IToken;
  isTop3?: boolean;
  isTokenCard?: boolean;
};

const Card = ({ isTop3, isTokenCard, token }: Props) => {
  const { ethPrice } = useContext(Context);
  const [isError, setIsError] = useState(false);
  return (
    <CardLink address={token.address} isTokenCard={isTokenCard}>
      <div className={classNames("card", { "top3-card": isTop3, "token-info": isTokenCard })}>
        <img onError={() => setIsError(true)} src={isError ? "/no_data.jpg" : token.extendedData.image} alt="top 3 token img" />
        <div className="card-info">
          <p>
            <span>[ {token.symbol} ]</span> {token.name}
          </p>
        </div>
        <Progress value={token.marketCap * ethPrice} maxValue={ethPrice * MAX_ETH_VALUE} />
      </div>
    </CardLink>
  );
};

const CardLink = ({ address, isTokenCard, children }: { address: string; isTokenCard?: boolean; children: React.ReactNode }) => {
  if (isTokenCard) return <div>{children}</div>;
  return <Link to={`/token/${address}`}>{children}</Link>;
};

export default Card;
