import React, { useEffect, useRef } from "react";
import "./Chart.scss";
import { ChartingLibraryWidgetOptions, LanguageCode, ResolutionString, widget } from "../../../charting_library";
import { IToken } from "../../../api/types";

export interface ChartContainerProps {
  symbol: ChartingLibraryWidgetOptions["symbol"];
  interval: ChartingLibraryWidgetOptions["interval"];
  datafeedUrl: string;
  libraryPath: ChartingLibraryWidgetOptions["library_path"];
  chartsStorageUrl: ChartingLibraryWidgetOptions["charts_storage_url"];
  chartsStorageApiVersion: ChartingLibraryWidgetOptions["charts_storage_api_version"];
  clientId: ChartingLibraryWidgetOptions["client_id"];
  userId: ChartingLibraryWidgetOptions["user_id"];
  fullscreen: ChartingLibraryWidgetOptions["fullscreen"];
  autosize: ChartingLibraryWidgetOptions["autosize"];
  studiesOverrides: ChartingLibraryWidgetOptions["studies_overrides"];
  container: ChartingLibraryWidgetOptions["container"];
  height: ChartingLibraryWidgetOptions["height"];
}

const getLanguageFromURL = (): LanguageCode | null => {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null ? null : (decodeURIComponent(results[1].replace(/\+/g, " ")) as LanguageCode);
};

export interface ChartData {
  value: number;
  time: any;
}

type ChartPropsType = {
  token: IToken;
};

const Chart = ({ token }: ChartPropsType) => {
  const chartContainerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;

  const defaultProps: Omit<ChartContainerProps, "container"> = {
    symbol: token.symbol,
    interval: "1" as ResolutionString,
    // datafeedUrl: "https://demo_feed.tradingview.com",
    datafeedUrl: "https://api.castify.io/api/Market",
    libraryPath: "/charting_library/",
    chartsStorageUrl: "https://saveload.tradingview.com",
    chartsStorageApiVersion: "1.1",
    clientId: "tradingview.com",
    userId: "public_user_id",
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
    height: 600,
  };

  useEffect(() => {
    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: defaultProps.symbol as string,
      datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(defaultProps.datafeedUrl, 1000),
      interval: defaultProps.interval as ChartingLibraryWidgetOptions["interval"],
      container: chartContainerRef.current,
      library_path: defaultProps.libraryPath as string,
      locale: getLanguageFromURL() || "en",
      disabled_features: ["use_localstorage_for_settings"],
      enabled_features: ["study_templates"],
      charts_storage_url: defaultProps.chartsStorageUrl,
      charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      client_id: defaultProps.clientId,
      user_id: defaultProps.userId,
      autosize: defaultProps.autosize,
      theme: "dark",
      // debug: true,
      timezone: "exchange",
      fullscreen: defaultProps.fullscreen,
      height: defaultProps.height,

      symbol_search_complete: (symbol, searchResultItem) => {
        return new Promise((resolve) => {
          resolve({ symbol: symbol, name: symbol });
        });
      },
    };

    const tvWidget = new widget(widgetOptions);

    // tvWidget.onChartReady(() => {
    // tvWidget.headerReady().then(() => {
    //   const button = tvWidget.createButton();
    //   button.setAttribute("title", "Click to show a notification popup");
    //   button.classList.add("apply-common-tooltip");
    //   button.addEventListener("click", () =>
    //     tvWidget.showNoticeDialog({
    //       title: "Notification",
    //       body: "TradingView Charting Library API works correctly",
    //       callback: () => {
    //         console.log("Noticed!");
    //       },
    //     })
    //   );
    //   button.innerHTML = "Check API";
    // });
    // });

    return () => {
      tvWidget.remove();
    };
  }, []);

  return (
    <div className="chart">
      <div ref={chartContainerRef} className={"TVChartContainer"} />
    </div>
  );
};

export default Chart;
