import React from "react";
import "./Progress.scss";

type Props = {
  value: number;
  maxValue: number;
};

const Progress = ({ value, maxValue }: Props) => {
  return (
    <div className="progress-bar">
      <div className="progress-bar--filled" style={{ width: `${((value > maxValue ? maxValue : value) / maxValue) * 100}%` }}></div>
      <span className="progress-percent">{(((value ? value : maxValue) / maxValue) * 100).toFixed(2)}%</span>
    </div>
  );
};

export default Progress;
