import React from "react";
import Top3 from "./Top3/Top3";
import AllTokens from "./AllTokens/AllTokens";
import "./Tokens.scss";

const Tokens = () => {
  return (
    <div className="tokens">
      <Top3 />
      <AllTokens />
    </div>
  );
};

export default Tokens;
