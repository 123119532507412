import React from "react";

type Props = { label: string; value: any };

const TokenValueInfo = ({ label, value }: Props) => {
  return (
    <div className="token-value">
      <p className="token-value-label">{label}</p>
      <p className="token-value-value">{value}</p>
    </div>
  );
};

export default TokenValueInfo;
