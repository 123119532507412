import React, { useMemo } from "react";
import ReactPaginate from "react-paginate";

type Props = {
  pagination: {
    page: number;
    size: number;
    total: number;
  };
  setPagination: React.Dispatch<
    React.SetStateAction<{
      page: number;
      size: number;
      total: number;
    }>
  >;
  isLoading: boolean;
};

const AllTokensPagination = ({ pagination, setPagination, isLoading }: Props) => {
  const PageCounts = useMemo(() => {
    if (pagination.total <= pagination.size) return 1;
    let pageNumbers = pagination.total / pagination.size;
    if (pageNumbers.toString().includes(".")) {
      pageNumbers = Math.trunc(pageNumbers) + 1;
    }
    return pageNumbers;
  }, [pagination.size, pagination.total]);

  if (isLoading) return null;

  const onClickPagination = (page: number) => {
    setPagination((prev) => ({ ...prev, page: page }));
  };

  const handlePageClick = ({ selected }: { selected: number }) => {
    onClickPagination(selected + 1);
    document.getElementById("all-tokens")?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="tokens-pagination">
      <ReactPaginate
        forcePage={pagination.page - 1}
        breakLabel="..."
        nextLabel=" > "
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        activeClassName="page-number--active"
        pageCount={PageCounts}
        previousLabel=" < "
        renderOnZeroPageCount={null}
        pageClassName="page-number"
        containerClassName="pagination-wrapper"
        pageLinkClassName="page-number"
        nextClassName="pagination-controllers"
        previousClassName="pagination-controllers"
      />
    </div>
  );
};

export default AllTokensPagination;
