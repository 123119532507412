import React from "react";
import { IToken } from "../../../api/types";
import "./CheckTokenExist.scss";
import { Link } from "react-router-dom";
type Props = {
  children: React.ReactNode;
  token?: IToken | undefined;
};

const CheckTokenExist = ({ token, children }: Props) => {
  if (!token)
    return (
      <div className="not-exist-token">
        <p>Token not found!</p>
        <Link to={"/"} className="return-back-button">
          {"←"} return back
        </Link>
      </div>
    );
  return <>{children}</>;
};

export default CheckTokenExist;
