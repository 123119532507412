import React, { useEffect, useState } from "react";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
// import { getPriceEth } from "./api/api";
import Tokens from "./Page/Tokens/Tokens";
import Token from "./Page/Token/Token";
import { ColorType, createChart } from "lightweight-charts";
import { getPriceEth } from "./api/api";
import * as amplitude from "@amplitude/analytics-browser";
import { useAccount } from "wagmi";

export const Context = React.createContext({ ethPrice: 0 });

const App = () => {
  const [ethPrice, setEthPrice] = useState(0);
  const account = useAccount();

  useEffect(() => {
    amplitude.init("98c1edead5d4139fbe47db2b87fdb3ed", { autocapture: true });
  }, []);

  useEffect(() => {
    getPriceEth().then((res) => {
      if (res?.USD) setEthPrice(res.USD);
    });
  }, []);

  useEffect(() => {
    if (account) amplitude.setUserId(account.address);
  }, []);

  return (
    <Context.Provider value={{ ethPrice: ethPrice }}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Tokens />} />
          <Route path="/token/:hash" element={<Token />} />
          <Route path="*" element={<Navigate to={"/"} />} />
        </Route>
      </Routes>
    </Context.Provider>
  );
};

export default App;
