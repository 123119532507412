import React from "react";
import { IToken } from "../../../api/types";
import "./TokenListed.scss";
import linkImg from "./link.svg";

type Props = {
  token: IToken;
  children: React.ReactNode;
};

const TokenListed = ({ token, children }: Props) => {
  if (token.graduated) return <TokenGraduated token={token} />;
  return <>{children}</>;
};

const TokenGraduated = ({ token }: { token: IToken }) => {
  return (
    <div className="listed">
      <a target="_blank" href={`https://app.uniswap.org/explore/tokens/base/${token.address}`} rel="noreferrer">
        Token was listed on uniswap
      </a>
      <img src={linkImg} alt="LINK" />
    </div>
  );
};

export default TokenListed;
