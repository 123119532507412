import React from "react";
import Loader from "./Loader";

type Props = {
  children: React.ReactNode;
  loading?: boolean;
};

const LoaderWrapper = ({ children, loading }: Props) => {
  if (loading)
    return (
      <div className="loading-wrapper">
        <Loader />
      </div>
    );
  return <>{children}</>;
};

export default LoaderWrapper;
