import React from "react";
import "./Top3.scss";
import Card from "../Card/Card";
import { useQuery } from "@tanstack/react-query";
import { getTopToken } from "../../../api/api";

const Top3 = () => {
  const query = useQuery({ queryKey: ["top 1 token"], queryFn: getTopToken });

  return (
    <div className="top1">
      <h1>TOP 1</h1>
      <div className="top1-list">{query.data && <Card token={query.data.tokens?.[0]} isTop3 key={query.data.tokens?.[0].address} />}</div>
    </div>
  );
};

export default Top3;
