import { useQuery } from "@tanstack/react-query";
import { readContract } from "@wagmi/core";
import React, { useState } from "react";
import { config } from "../../../wagmi";
import abiERC20 from "../../../abiERC20.json";
import { useAccount } from "wagmi";
import { formatEther } from "ethers";

type Props = {
  setMarketValue: React.Dispatch<React.SetStateAction<string>>;
  symbol: string;
  tokenAddress: string;
};

const BalanceToken = ({ setMarketValue, symbol, tokenAddress }: Props) => {
  const account = useAccount();
  const [balance, setbalance] = useState("0");

  useQuery({
    queryKey: [tokenAddress],
    queryFn: () =>
      readContract(config, {
        abi: abiERC20,
        address: tokenAddress as `0x${string}`,
        functionName: "balanceOf",
        args: [account.address],
      }).then((res: any) => {
        const etherValue = formatEther(BigInt(res));
        setbalance(etherValue);
        return res;
      }),
    refetchInterval: 3000,
  });

  return (
    <p onClick={() => setMarketValue(`${balance}`)} className="user-balance">
      Token balance: {balance} {symbol}
    </p>
  );
};

export default BalanceToken;
