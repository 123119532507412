import axios from "axios";
import { IToken, ITokensReq } from "./types";

export const _API_BASE = "https://api.castify.io";
const _API = `${_API_BASE}/api`;

const req = axios.create({
  baseURL: _API,
});

const parseData = (data: any) => {
  if (data?.data?.data) return data.data.data;
  return null;
};

export const getAllTokens = ({
  pageSize = 10,
  pageNumber = 1,
  orderByMCap = false,
  hideGraduated = false,
  search = "",
}): Promise<ITokensReq> => {
  return req
    .get(
      `/Tokens/GetTokens?pageSize=${pageSize}&pageNumber=${pageNumber}&orderByMCap=${orderByMCap}&hideGraduated=${hideGraduated}${
        search ? `&searchQuery=${search}` : ""
      }`
    )
    .then(parseData)
    .catch((err) => console.log(err));
};

export const getTopToken = (): Promise<ITokensReq> => {
  return req
    .get(`/Tokens/GetTokens?pageSize=1&pageNumber=1&orderByMCap=true&hideGraduated=true`)
    .then(parseData)
    .catch((err) => console.log(err));
};

export const getTokenByHash = (hash: string): Promise<IToken> => {
  return req
    .get(`/Tokens/GetTokenByAddress?address=${hash}`)
    .then(parseData)
    .catch((err) => console.log(err));
};

export const getPriceEth = () => {
  return axios
    .get(`https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD`)
    .then((res) => {
      if (res) return res.data;
    })
    .catch((err) => console.log(err));
};

export const getTxs = (tokenAddress: string) => {
  return axios.get(`${_API}/Tokens/GetTxs?tokenAddress=${tokenAddress}`).catch((err) => console.log(err));
};

export const getHolders = (tokenAddress: string) => {
  return axios.get(`${_API}/Tokens/GetHolders?tokenAddress=${tokenAddress}`).catch((err) => console.log(err));
};
