import { ConnectButton } from "@rainbow-me/rainbowkit";
import React from "react";
import { useAccount } from "wagmi";

type Props = {
  children: React.ReactNode;
};

const WalletChecker = ({ children }: Props) => {
  const account = useAccount();

  if (!account || !account.address)
    return (
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <ConnectButton />
      </div>
    );

  return <>{children}</>;
};

export default WalletChecker;
