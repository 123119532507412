export const formatBalance = (str: string | null) => {
  if (!str) return;
  return Math.trunc(Number(str) * 100_000) / 100_000;
};

export const formatNumber = (num: number) => {
  // Преобразуем число в строку
  let str = num.toString();

  // Ищем позицию точки в строке
  const decimalIndex = str.indexOf(".");

  // Если точка не найдена, значит число целое и ничего не нужно делать
  if (decimalIndex === -1) {
    return str;
  }

  // Определяем количество значений после точки
  const decimalPartLength = str.length - decimalIndex - 1;

  // Если значащих чисел меньше 3, возвращаем число как есть
  if (decimalPartLength <= 3) {
    return str;
  }

  // Обрезаем строку до нужного количества знаков после запятой
  return str.slice(0, decimalIndex + 10);
};

const secondsBetween = (DATE: Date) => {
  return (DATE.valueOf() - new Date().valueOf()) / 1000;
};

const formaterTime = (time: number) => {
  if (time.toString().length === 1) return "0" + `${time}`;
  else return time;
};

export const DaysUntil = (DATE: Date) => {
  let days = Math.floor(secondsBetween(DATE) / (3600 * 24));
  if (days < 0) days = 0;
  let hours = Math.floor((secondsBetween(DATE) % (3600 * 24)) / 3600);
  if (hours < 0) hours = 0;
  let minutes = Math.floor((secondsBetween(DATE) % 3600) / 60);
  if (minutes < 0) minutes = 0;
  let seconds = Math.floor(secondsBetween(DATE) % 60);
  if (seconds < 0) seconds = 0;
  return formaterTime(days) + " : " + formaterTime(hours) + " : " + formaterTime(minutes) + " : " + formaterTime(seconds);
};
