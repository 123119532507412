import React, { useState } from "react";
import "./Controlls.scss";
import WalletChecker from "../../../components/WalletChecker/WalletChecker";
import Card from "../../Tokens/Card/Card";
import { IToken } from "../../../api/types";
import BalanceETH from "./BalanceETH";
import BalanceToken from "./BalanceToken";
import { config } from "../../../wagmi";
import abiFoundry from "../../../abiFoundry.json";
import { parseEther } from "ethers";
import classNames from "classnames";
import { readContract, writeContract } from "@wagmi/core";
import { useAccount } from "wagmi";
import { useAddRecentTransaction } from "@rainbow-me/rainbowkit";
import { FOUNDRY } from "../../../_constants";
import TokenListed from "../TokenListed/TokenListed";
import * as amplitude from "@amplitude/analytics-browser";

type ControllType = {
  token: IToken;
};

const Controlls = ({ token }: ControllType) => {
  const [marketValue, setMarketValue] = useState<string>("");
  const [userBalance, setUserBalance] = useState(0);
  const [slippage, setSlippage] = useState("");
  const account = useAccount();
  const addRecentTransaction = useAddRecentTransaction();

  const onBuy = async () => {
    if (!marketValue) return;
    try {
      amplitude.track("BUY", { symbol: token.symbol });
      const simulateNumber = await readContract(config, {
        abi: abiFoundry,
        address: FOUNDRY,
        functionName: "calcAmountOutFromEth",
        args: [token.address, parseEther(marketValue)],
      });

      const slipp = !slippage ? 0.95 : +slippage === 0 ? 1 : 1 - +slippage / 100;

      const minOutput = Math.floor(Number(simulateNumber) * slipp);

      // GA_ClickBuy(tokenData.symbol);

      const hash = await writeContract(config, {
        abi: abiFoundry,
        address: FOUNDRY,
        functionName: "swapEthForTokens",
        args: [token.address, parseEther(marketValue), BigInt(minOutput), account.address, Math.round(Date.now() / 1000) + 60],
        value: parseEther(marketValue),
      });

      addRecentTransaction({
        hash: hash,
        description: `Buy ${marketValue} $${token.symbol}`,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onSell = async () => {
    if (!marketValue) return;
    try {
      amplitude.track("SELL", { symbol: token.symbol });
      const simulateNumber = await readContract(config, {
        abi: abiFoundry,
        address: FOUNDRY,
        functionName: "calcAmountOutFromToken",
        args: [token.address, parseEther(marketValue)],
      });

      const slipp = !slippage ? 0.95 : +slippage === 0 ? 1 : 1 - +slippage / 100;

      const minOutput = Math.floor(Number(simulateNumber) * slipp);

      // GA_ClickSell(tokenData.symbol);

      const hash = await writeContract(config, {
        abi: abiFoundry,
        address: FOUNDRY,
        functionName: "swapTokensForEth",
        args: [token.address, parseEther(marketValue), BigInt(minOutput), account.address, Math.round(Date.now() / 1000) + 60],
      });

      addRecentTransaction({
        hash: hash,
        description: `Sold ${marketValue} $${token.symbol}`,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="token-data-container">
      <div className={classNames("controlls", token?.graduated && "controlls--listed")}>
        <TokenListed token={token}>
          <div className="market-input-container">
            <div className="slippage-container">
              <p>slippage</p>
              <input
                value={slippage}
                onChange={(e) => setSlippage(e.target.value)}
                max={100}
                min={0}
                className={classNames("slippage", { "slippage-error": slippage && (+slippage > 100 || +slippage < 0) })}
                type="number"
                onKeyDown={(e) => {
                  if (["e", "E", "-", ".", ","].includes(e.key)) e.preventDefault();
                }}
                placeholder="5%"
              />
            </div>
            <input
              value={marketValue}
              onChange={(e) => setMarketValue(e.target.value)}
              className="market-input"
              min={0}
              step={0.01}
              type="number"
              placeholder="1 eth"
              onKeyDown={(e) => {
                if (["e", "E", "-"].includes(e.key)) e.preventDefault();
              }}
            />
            <div className="balance-container">
              <BalanceETH setMarketValue={setMarketValue} />
              <BalanceToken tokenAddress={token.address} symbol={token.symbol} setMarketValue={setMarketValue} />
            </div>
          </div>
          <WalletChecker>
            <div className="controll-buttons">
              <button className="buy" onClick={onBuy}>
                BUY
              </button>
              <button className="sell" onClick={onSell}>
                SELL
              </button>
            </div>
          </WalletChecker>
        </TokenListed>
      </div>
      <div>
        <Card isTokenCard token={token} />
      </div>
    </div>
  );
};

export default Controlls;
